import { Close, OndemandVideo, ZoomIn } from "@mui/icons-material";
import { Box, Breadcrumbs, ButtonGroup, IconButton, ImageList, ImageListItem, ImageListItemBar, Typography, useMediaQuery, useTheme } from "@mui/material";
import Link, { LinkProps } from '@mui/material/Link';
import { useState } from "react";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import { useDispatch } from "react-redux";
import { Link as RouterLink, useNavigate, useParams } from "react-router-dom";
import { TransformComponent, TransformWrapper } from "react-zoom-pan-pinch";
import i18n, { getSeries, Workdesc } from "../i18n";
import { changeSizes } from "../state/GallerySize";
import { change } from "../state/Nav";
import Video from "./Video";
import Painting from "./Painting";
import { hideKrpano } from "../Util";

interface LinkRouterProps extends LinkProps {
    to: string;
    replace?: boolean;
}

const size = 500;
const wh = size * 607.0 / 853.0;
const maxWidthMobile = "660px";
const landscape = "landscape";
const portrait = "portrait";
let cols = 3;
const gap = 45;
const minLeft = 80;

export default function Gallery() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    dispatch(change("gallery"));
    const handle = useFullScreenHandle();
    const theme = useTheme();
    const [mode, setMode] = useState("");

    const params = useParams();
    const isNotAll = (params.serie != "all");

    let heading = headingAll;
    let itemData = itemDataAll;

    hideKrpano();

    if (isNotAll) {
        itemData.map((val) => {
            if (val.name === params.serie!) {
                heading = "Arbeiten aus dem Bilderzyklus '" + val.title + "'";
            }
        })
        itemData = getSeries(params.serie!);
    }

    let imageDataPortrait: ImageDesc[] = [];
    let imageDataLandscape: ImageDesc[] = [];
    itemData.map((item) => {
        if (item.format === landscape) imageDataLandscape.push(item);
        if (item.format === portrait) imageDataPortrait.push(item);
    });

    let sizeOneColumn = imageDataLandscape.length == 0 ? wh : size;
    sizeOneColumn += 5;

    let sizeTwoColumn = 2 * wh;
    if (imageDataLandscape.length > 2) {
        sizeTwoColumn = 2 * size;
    }
    else if (imageDataLandscape.length == 2) {
        sizeTwoColumn = wh + size;
    }
    else if (imageDataLandscape.length == 1) {
        if (imageDataPortrait.length % 2 == 1) {
            sizeTwoColumn = wh + size;
        }
    }
    sizeTwoColumn += gap + 2 * 5;

    const sizeThreeColumn = 3 * (wh + 5) + 2 * gap;
    dispatch(changeSizes([sizeOneColumn, sizeTwoColumn, sizeTwoColumn]));

    const breakPoint = 1600;
    const isMobile = useMediaQuery("(max-width:" + maxWidthMobile + ")");
    let isSmall1 = useMediaQuery("(max-width:" + (breakPoint) + "px)");
    let isSmall2 = useMediaQuery("(max-width:" + (0.9 * (breakPoint)) + "px)");
    let isSmall3 = useMediaQuery("(max-width:" + (0.8 * (breakPoint)) + "px)");
    let isSmall4 = useMediaQuery("(max-width:" + (0.7 * (breakPoint)) + "px)");
    let isSmall5 = useMediaQuery("(max-width:" + (0.6 * (breakPoint)) + "px)");
    const [paintingName, setPaintingName] = useState("");

    let scale = 1.0;
    let fScale = 1.0;
    if (isSmall1) {
        scale = 0.9;
        fScale = 0.9;
    }
    if (isSmall2) {
        scale = 0.8;
        fScale = 0.81;
    }
    if (isSmall3) {
        scale = 0.7;
        fScale = 0.72;
    }
    if (isSmall4) {
        scale = 0.6;
        fScale = 0.63;
    }
    if (isSmall5) {
        scale = 0.5;
        fScale = 0.54;
    }

    itemData = [];
    for (var i = 0; i < imageDataPortrait.length; i++) {
        itemData.push(imageDataPortrait[i]);
    }
    for (var i = 0; i < imageDataLandscape.length; i++) {
        itemData.push(imageDataLandscape[i]);
    }
    let nrPortraits = imageDataPortrait.length;
    if (imageDataPortrait.length > 0 && imageDataPortrait.length % 3 == 1) nrPortraits += 1;
    let nrRowsLandscape = (imageDataPortrait.length % 3 == 1) ? imageDataLandscape.length - 1 : imageDataLandscape.length;
    nrRowsLandscape = Math.floor((nrRowsLandscape - 1) / 2) + 1;
    const nrRowsPortrait = Math.floor((imageDataPortrait.length - 1) / 3) + 1;

    const maxWidthBox = isMobile ? "80%" : "800px";
    const paddingTopBox = isMobile ? "0px" : "2.3vh";
    const paddingLeftBox = isMobile ? "30px" : "0px";
    const paddingImageList = isMobile ? "0px 30px" : "5px";
    const display = handle.active ? "block" : "none";
    const crumbColor = (params.serie != "all") ? "#b7979c" : "black";

    //console.log("nrRowsPortrait:" + nrRowsPortrait);
    const fullsize = handle.active && paintingName != "";
    let w = theme.painting.landscape.width;
    let h = theme.painting.landscape.height;
    let x: Workdesc;
    let xmode = mode;
    let videoPath = "";
    if (paintingName != "") {
        x = i18n(paintingName, "de");
        videoPath = "/video/de/" + paintingName + ".mp4";
        if (xmode === "") {
            xmode = (x.hasVideo === "true") ? "video" : "image";
        }
        if (x.format === "portrait") {
            w = theme.painting.portrait.width;
            h = theme.painting.portrait.height;
        }
        if (w * window.visualViewport!.height > h * window.visualViewport!.width) {
            h = h * window.visualViewport!.width / w;
            w = window.visualViewport!.width;
        }
        else {
            w = w * window.visualViewport!.height / h;
            h = window.visualViewport!.height;
        }
    }
    let rowIdx = 1;
    let currentCols = 0;

    return (
        <Box>
            {!isMobile &&
                <Box>
                    <Box sx={{ maxWidth: maxWidthBox, paddingTop: paddingTopBox, paddingLeft: paddingLeftBox, paddingBottom: "10px" }}>
                        <Breadcrumbs separator="›">
                            {params.serie != "all" &&
                                <Box>
                                    <Link component={RouterLink as any} to="/gallery/all" underline="none" color="inherit" href="/">
                                        <Typography variant="body2" sx={{ fontSize: "0.925rem", color: "black" }}>
                                            Bilderzyklen
                                        </Typography>
                                    </Link>
                                </Box>
                            }
                            <Typography variant="body2" sx={{ fontSize: "0.925rem", color: crumbColor }}>
                                {heading}
                            </Typography>
                        </Breadcrumbs>
                    </Box>

                    <Box sx={{ width: scale * sizeThreeColumn }}>
                        <ImageList rowHeight={fScale * 550} key={Math.floor(Math.random() * 1000)} sx={{ width: "100%", padding: paddingImageList }} cols={15} gap={scale * 45} >
                            {itemData.map((item, idx) => {
                                let w = (item.format === "portrait") ? wh : size;
                                const h = (item.format === "portrait") ? size : wh;
                                let title = item.title;
 
                                let cols = (item.format === "portrait") ? 5 : 7;
                                if (currentCols + cols > 15) {
                                    currentCols = cols;
                                    rowIdx++;
                                }
                                else {
                                    currentCols += cols;
                                }
                                const ytrans = (rowIdx <= nrRowsPortrait + 1) ? 0 : (rowIdx - nrRowsPortrait - 1) * 150 * scale;
 
                                //console.log("ytrans: " + ytrans);
                                return (
                                    <ImageListItem cols={cols} key={item.name} sx={{ transform: "translate(0px,-" + ytrans + "px)" }}>
                                        <img
                                            src={"/img/" + item.image + ".jpg" + "?versionNr=" + item.versionNr}
                                            loading="lazy"
                                            style={{ maxWidth: scale * w, maxHeight: scale * h, margin: "0", cursor: "pointer", boxShadow: "0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12)", marginBottom: "0px" }}
                                            onClick={() => {
                                                if (params.serie === "all") {
                                                    navigate("/gallery/" + item.name);
                                                }
                                                else {
                                                    navigate("/paintings/" + item.name);
                                                }
                                            }}
                                        />
                                        <ImageListItemBar position="below" sx={{ marginLeft: "0%", alignItems: "center", paddingTop: (10 * scale) + "px", paddingBottom: "0px", textAlign: "center", maxWidth: "100%", background: "inherit" }}
                                            title={title}
                                        />
                                    </ImageListItem>
                                )
                            }
                            )}
                        </ImageList>
                    </Box>
                </Box>
            }
            {
                isMobile &&
                <Box>
                    <Box sx={{ maxWidth: maxWidthBox, paddingTop: paddingTopBox, paddingLeft: paddingLeftBox }}>
                        <Breadcrumbs separator="›">
                            {params.serie != "all" &&
                                <Box>
                                    <Link component={RouterLink as any} to="/gallery/all" underline="none" color="inherit" href="/">
                                        <Typography variant="body2" sx={{ fontSize: "0.925rem", display: "block" }}>
                                            Bilderzyklen
                                        </Typography>
                                    </Link>
                                </Box>
                            }
                            <Typography variant="body2" sx={{ fontSize: "0.925rem", display: "block" }}>
                                {heading}
                            </Typography>
                        </Breadcrumbs>
                    </Box>

                    <Box sx={{}}>
                        <ImageList sx={{ padding: paddingImageList }} cols={1} gap={45} >
                            {itemData.map((item) => (
                                <ImageListItem key={item.name} >
                                    <img
                                        src={"/img/" + item.image + ".jpg"}
                                        loading="lazy"
                                        style={{ cursor: "pointer", boxShadow: "0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12)" }}
                                        onClick={() => {
                                            if (params.serie === "all") {
                                                navigate("/gallery/" + item.name);
                                            }
                                            else {
                                                handle.enter();
                                                setPaintingName(item.name);
                                            }
                                        }}
                                    />

                                    <ImageListItemBar position="below" sx={{ paddingTop: "10px", textAlign: "center", maxWidth: "100%", background: "inherit" }}
                                        title={item.title}
                                    />
                                </ImageListItem>
                            ))}
                        </ImageList>
                    </Box >
                    <FullScreen handle={handle}>
                        {fullsize &&
                            <Painting name={paintingName} fromTour={false} fullscreenhandle={handle}></Painting>
                        }
                    </FullScreen>
                </Box>
            }
        </Box >
    );
}

interface ImageDesc { name: string, title: string, image: string, format: string, versionNr: string };

const headingAll = "Die verschiedenen Bilderzyklen der Künstlerin sind jeweils einem thematischen Schwerpunkt gewidmet.";

const itemDataAll = [
    {
        name: "Serie_Zauberfloete",
        title: "Die Zauberflöte",
        image: "small/Koenigin_der_Nacht",
        format: "portrait",
        versionNr: "0"
    },
    {
        name: "Serie_AdamUndEva",
        title: "Adam und Eva",
        image: "small/Adam_und_Eva4",
        format: "portrait",
        versionNr: "0"
    },
    {
        name: "Serie_Exlibris",
        title: "Exlibris",
        image: "small/Hamlet",
        format: "landscape",
        versionNr: "0"
    },
    {
        name: "Serie_Zirkus",
        title: "Zirkus",
        image: "small/Zirkus4",
        format: "portrait",
        versionNr: "0"
    },
    {
        name: "Serie_Augustin",
        title: "Der Liebe Augustin",
        image: "small/Augustin2",
        format: "portrait",
        versionNr: "0"
    },
    {
        name: "Serie_Heilige",
        title: "Heilige",
        image: "small/Heiliger_Nepomuk",
        format: "portrait",
        versionNr: "0"
    },
    {
        name: "Serie_Pinocchio",
        title: "Pinocchio",
        image: "small/PinocchioDante3",
        format: "landscape",
        versionNr: "0"
    },
    {
        name: "Serie_Bibel",
        title: "Biblisches",
        image: "small/Arche_Noah1",
        format: "landscape",
        versionNr: "0"
    },
    {
        name: "Serie_Buchstaben",
        title: "Buchstäbliches",
        image: "small/Buchstabe_M",
        format: "portrait",
        versionNr: "0"
    },
    {
        name: "Serie_Babylon",
        title: "Vermischtes",
        image: "small/Mondphasen",
        format: "portrait",
        versionNr: "0"
    }
];
